/**
 * @generated SignedSource<<a234eb050f2c62c3bab34f418c202113>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { ReaderInlineDataFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type AssigneePickerAssignee$data = {
  readonly __typename: string;
  readonly avatarUrl: string;
  readonly id: string;
  readonly isCopilot?: boolean;
  readonly login: string;
  readonly name: string | null | undefined;
  readonly " $fragmentType": "AssigneePickerAssignee";
};
export type AssigneePickerAssignee$key = {
  readonly " $data"?: AssigneePickerAssignee$data;
  readonly " $fragmentSpreads": FragmentRefs<"AssigneePickerAssignee">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "AssigneePickerAssignee"
};

(node as any).hash = "3a87754bdfdc0a73ea2907400f449fa0";

export default node;
